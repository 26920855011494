import { graphql, PageProps } from 'gatsby'
import React from 'react'
import { Container } from 'react-bootstrap'
import { Heading } from '../components/Heading'
import { Layout } from '../components/Layout'
import { Section } from '../components/Section'
import SEO from '../components/seo'
import { Must, PageQuery } from '../types'

export default function PageTemplate({ data }: PageProps<Must<PageQuery>>) {
  const { frontmatter, html } = data.markdownRemark
  return (
    <Layout>
      <SEO title={frontmatter.title} meta={frontmatter.meta} />
      <Section as={Container}>
        <Heading>{frontmatter.title}</Heading>
        <div className="page" dangerouslySetInnerHTML={{ __html: html }} />
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Page($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      frontmatter {
        title
        meta {
          name
          content
        }
      }
    }
  }
`
